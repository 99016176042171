<template>
    <page-layout-nolo :loading="isLoading" :title="$t('register')" :back="$t('btn.backToLogin')">
        <v-container>
            <v-form v-model="valid">
            <v-card class="pt-4 mb-10 elevation-8">
                <v-card-text>
<!--                    <google-maps-auto-complete />-->
                    <div class="nolo-h6 mb-6">{{ $t('yourDetails') }}</div>
                    <personal-details-edit unique-email v-model="personalDetails" @valid="v => personalValid = v"/>
                    <v-row dense>
                        <v-col cols="12" sm="6" class="pr-sm-2">
                            <v-text-field outlined type="password" :label="$t('label.newPassword')"
                                          required autocomplete="new-password"
                                          :rules="[inputRules.requiredPassword, inputRules.validPassword]"
                                          v-model="newPassword"/>
                        </v-col>
                        <v-col cols="12" sm="6" class="pl-sm-2">
                            <v-text-field outlined type="password" :label="$t('label.confirmPassword')"
                                          required autocomplete="new-password"
                                          :rules="[validConfirmation]"
                                          v-model="confirmPassword"/>

                        </v-col>
                        <v-col cols="12">
                            <div class="nolo-h6 mb-6">{{ $t('yourAddress') }}</div>
                            <address-edit v-model="address" without-name @valid="v => addressValid = v"/>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox class="mt-0" v-model="marketing" :label="$t('label.marketing')"/>
                            <i18n tag="div" path="message.marketingOptIn" class="nolo-subcopy px-8 text-center text-md-left">
                                <a  @click.stop :href="$t('url.privacy', {domain: cmsDomain})" target="_blank">{{ $t('link.privacyPolicy') }}</a>
                            </i18n>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox v-model="terms">
                                <template v-slot:label>
                                    <i18n tag="span" path="label.acceptTerms">
                                        <a @click.stop :href="$t('url.terms', {domain: cmsDomain})" target="_blank">{{ $t('link.termsAndConditions') }}</a>
                                    </i18n>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="px-4 pt-0 pb-6">
                    <v-row dense>
                        <v-col cols="12" md="3">
                            <v-btn @click="cancel" block outlined color="secondary">{{ $t('btn.cancel') }}</v-btn>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn @click="save" block depressed :disabled="!validForm" color="primary">{{ $t('btn.completeRegistration') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            </v-form>
        </v-container>
        <ok-dialog v-model="showRegisterComplete" icon="mdi-account-plus-outline"
                   :title="$t('registrationComplete')" @ok="gotoLogin" :message="$t('message.registrationComplete')"/>
    </page-layout-nolo>
</template>

<script>

    import APICustomer from "../../api/APICustomer";
    import PersonalDetailsEdit from "../../components/Account/PersonalDetailsEdit";
    import AddressEdit from "../../components/Account/AddressEdit";
    import OkDialog from "../../components/UI/OkDialog";

    export default {
        name: "Register",
        components: {OkDialog, AddressEdit, PersonalDetailsEdit},
        data() {
            return {
                isLoading: false,
                password: '',
                newPassword: '',
                confirmPassword: '',
                personalDetails: {
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    email: ''
                },
                address: {
                    firstname: '',
                    lastname: '',
                    address_1: '',
                    address_2: '',
                    city: '',
                    postcode: '',
                    lat_lng: '',
                },
                valid: false,
                personalValid: false,
                addressValid: false,
                marketing: false,
                terms: false,
                showRegisterComplete: false
            }
        },
        mounted() {
        },
        methods: {
            cancel() {
                this.$router.back();
            },
            gotoLogin() {
                this.$router.back();
                // this.$router.replace({name: 'account'});
            },
            save() {
                const me = this;
                APICustomer.register(this, {
                    ...this.address,
                    ...this.personalDetails,
                    password: this.newPassword,
                    terms: this.terms,
                    marketing: this.marketing
                })
                    .then(() => {
                        me.justRegistered = true;
                        me.showRegisterComplete = true;
                    })
                    .catch(
                        () => {
                            me.appShowOops()
                        }
                    )
            },
            validConfirmation(v) {
                return v === this.newPassword ? true : this.$t('error.passwordDoesntMatch');
            },
        },
        computed: {
            justRegistered: {
                get() { return this.$store.getters['app/justRegistered']; },
                set(newValue) {
                    this.$store.commit('app/justRegistered', newValue);
                }
            },
            validForm() {
                return this.terms && this.valid && this.personalValid && this.addressValid;
            },
            customer: {
                get() {
                    return this.$store.getters['session/customer'];
                },
                set(newValue) {
                    this.$store.commit('session/customer', newValue)
                }
            }
        }
    }
</script>

<style scoped>

</style>

<template>
    <v-form v-model="isValid">
        <v-row dense v-if="address !== null">

            <v-col cols="12">
                <div v-if="!withoutName">
                    <v-text-field @change="changed" outlined v-model="address.firstname"
                                  :label="$t('label.firstName')"
                                  :rules="[inputRules.required]"
                                  required/>
                    <v-text-field @change="changed" outlined v-model="address.lastname" :label="$t('label.lastName')"
                                  :rules="[inputRules.required]"
                                  required/>
                </div>
                <v-text-field @change="changed" outlined v-model="address.address_1" :label="$t('label.address1')"
                              :rules="[inputRules.required]"
                              required/>
                <v-text-field @change="changed" outlined v-if="!hasZones" v-model="address.address_2"
                              :label="$t('label.address2')" required/>
                <v-text-field @change="changed" outlined v-model="address.city" :label="$t('label.city')"
                              :rules="[inputRules.required]"
                              required/>
                <v-autocomplete @change="changed" outlined v-if="hasZones" v-model="address.zone_id" :label="$t('label.zone')"
                          :items="zones"
                          :rules="[inputRules.required]"
                          item-value="zone_id" item-text="name" required/>
                <v-text-field @change="changed" outlined v-model="address.postcode" :label="$t('label.postcode')"
                              :rules="[inputRules.required]"
                              required/>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import APICustomer from "../../api/APICustomer";

    export default {
        name: "AddressEdit",
        data() {
            return {
                isValid: false,
                address: null,
                zones: []
            }
        },
        mounted() {
            const me = this;
            APICustomer.getZones(this)
                .then((response) => {
                    me.zones = response.data.data
                })
        },
        methods: {
            changed() {
                this.$emit('input', this.address);
            }
        },
        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.$emit('valid', newValue);
                }
            },
            value: {
                immediate: true, handler(newValue) {
                    this.address = {...this.defaultAddress, ...newValue};
                }
            },
        },
        props: {
            value: {type: Object},
            withoutName: {type: Boolean, default: false}
        },
        computed: {
            defaultAddress() {
                return {
                    "firstname": "",
                    "lastname": "",
                    "address_1": "",
                    "address_2": "",
                    "postcode": "",
                    "city": "london",
                    "zone_id": "0",
                    "zone": "Greater London",
                    "zone_code": "LDN",
                    "country_id": "222",
                    "country": "United Kingdom",
                    "iso_code_2": "GB",
                    "iso_code_3": "GBR",
                    "address_format": ""
                }
            },
            hasZones() {
                return this.zones.length > 0
            }
        }
    }
</script>

<style scoped>

</style>
